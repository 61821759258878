import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { useClubMemberNavItems } from '@aph/components/club-member/nav-items/use-club-member-nav-items';
import { DesktopMenu } from '@aph/components/club-member/page-layout/desktop-menu.component';
import { useIsNotMemberRedirect } from '@aph/components/club-member/redirect-hooks/use-is-not-member-redirect';
import { AphAccordion, BankIdLogInButton } from '@aph/components/common';
import { Breadcrumbs } from '@aph/components/layouts/breadcrumb.component';
import { MobileMenu } from '@aph/components/layouts/page-components/mobile-menu.component';
import { PageLayout } from '@aph/components/layouts/page-layout.component';
import { useGetSEOPropsWithFallbacks } from '@aph/hooks/use-get-seo-props-with-fallbacks/use-get-seo-props-with-fallbacks';
import { Typography } from '@aph/ui/components/typography/typography';
import { isErrorWithStatus } from '@aph/utilities/errors/is-error-with-status';
import { withPageErrorHandler } from '@aph/utilities/with-page-error-handler';
import { ContentApiClient } from '~/contentful/api/ContentApiClient';
import { ContentRenderer } from '~/contentful/components/content-renderer/content-renderer';
import { InfoBar } from '~/contentful/components/info-bar/info-bar';
import { RichTextComponent } from '~/contentful/components/rich-text/richtext.component';
import type { IComponentAccordionList, IInfoBar, IPageClubHjartat } from '~/contentful/types';
import { mapContentfulSEOToNextSEO } from '~/contentful/utilities/next-seo-from-contentful';
import { authRedirectConfig } from '~/contexts/auth/auth.redirect.config';
import { logger } from '~/logging';
import {
  useAmIMemberActions,
  useAmIMemberStore,
} from '~/model/member/am-i-member/use-am-i-member-store';

const MyPages: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  contentfulData,
  infoBar,
}) => {
  const seoProps = useGetSEOPropsWithFallbacks({
    pageTitle: contentfulData.fields.pageTitle,
    seoProps: mapContentfulSEOToNextSEO(contentfulData.fields.seo?.fields),
    customFallbacks: {
      title: 'Login as a club member',
      description: 'Logga in Klubb Hjärtat',
    },
  });

  const router = useRouter();
  const { amIMember } = useAmIMemberStore();
  const { getAmIMember } = useAmIMemberActions();
  const { isNotClubMemberRedirect } = authRedirectConfig();
  const notMemberRedirect = useIsNotMemberRedirect({ redirectUrl: isNotClubMemberRedirect });
  const isAuthenticated = amIMember.data && (amIMember.data.isMember || !amIMember.data.isMember);
  const isLoading = isAuthenticated === undefined || (notMemberRedirect && isAuthenticated);

  const { primaryNavItems, secondaryNavItems, allNavItems } = useClubMemberNavItems();
  const isLoadingNavItems = isAuthenticated === undefined;

  const { pageContent, pageTitle, ingress } = contentfulData.fields;

  useEffect(() => {
    if (amIMember.updated === 0) {
      getAmIMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && amIMember.updated > 0 && amIMember.data?.isMember) {
      router.push('/klubb-hjartat/start/');
    }
  }, [amIMember.data?.isMember, amIMember.updated, isLoading, router]);

  return (
    <PageLayout
      seo={seoProps}
      top={infoBar ? <InfoBar data={infoBar.fields} /> : undefined}
      hero={
        <div className="flex flex-col gap-2 md:max-w-2xl">
          <Breadcrumbs menuItems={allNavItems} />
          <div className="md:hidden">
            <MobileMenu menuItems={allNavItems} />
          </div>
          <Typography typography="headingExtraLarge" color="text-brand" asChild>
            <h1>{pageTitle}</h1>
          </Typography>
          <RichTextComponent field={ingress} />
        </div>
      }
      sidebar={
        <DesktopMenu
          primaryNavItems={primaryNavItems}
          secondaryNavItems={secondaryNavItems}
          isLoading={isLoadingNavItems}
        />
      }
    >
      {pageContent && (
        <div className="flex flex-col gap-2 md:max-w-2xl">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col md:flex-row">
              <BankIdLogInButton redirectLoginUrl="club_heart" />
            </div>
            <ContentRenderer
              content={pageContent}
              mappings={{
                componentAccordionList: (componentAccordionList: IComponentAccordionList) =>
                  componentAccordionListWrapper(componentAccordionList),
              }}
            />
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export const getServerSideProps = (async ({ res, req }) => {
  const pageSlug = 'klubb-hjartat/mina-sidor';
  const infoBarSlug = 'klubb-hjartat-info-bar';

  try {
    const contentApiClient = new ContentApiClient({ headers: req.headers });

    const [contentfulData, infoBar] = await Promise.all([
      contentApiClient.getEntityBySlug<IPageClubHjartat>({
        slug: pageSlug,
        contentType: 'pageClubHjartat',
      }),
      contentApiClient
        .getEntityBySlug<IInfoBar>({
          slug: infoBarSlug,
          contentType: 'infoBar',
        })
        .catch(() => null),
    ]);

    return {
      props: {
        contentfulData,
        infoBar,
      },
    };
  } catch (error: unknown) {
    logger.error(error, `Error in getServerSideProps with slug "${pageSlug}"`);
    if (isErrorWithStatus(error)) {
      if (error.status >= 500) {
        res.statusCode = 503;
        return {
          props: {
            error: { status: 503 },
            contentfulData: null as unknown as IPageClubHjartat,
            infoBar: null,
          },
        };
      }
    }

    return {
      notFound: true,
    };
  }
}) satisfies GetServerSideProps<{ contentfulData: IPageClubHjartat; infoBar: IInfoBar | null }>;

export default withPageErrorHandler(MyPages);

const componentAccordionListWrapper = (componentAccordionList: IComponentAccordionList) => {
  const { accordionItems } = componentAccordionList.fields;

  return (
    <div>
      {accordionItems.map((accordionItem) => {
        const { title, body } = accordionItem.fields;
        return (
          <AphAccordion
            key={title}
            id={title}
            accordionDetails={<RichTextComponent field={body} />}
            accordionSummary={title}
          />
        );
      })}
    </div>
  );
};
